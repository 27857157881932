import { Title, CardSubject, NavBrand} from "../components/Tools";
import { HashLink } from "react-router-hash-link";
import dautres from '../supports/dautres.png'; 
import { ContactButton } from "../components/Tools";

const Talk = () =>{
    return(
    <>
        <NavBrand />
        <center><Title text="D'autres en parlent - Sujet à venir"/></center>
        <br />
        <br />
        <div className="back-home"><HashLink smooth to="/#programme" className="back-home-link">Revenir à l'accueil</HashLink></div>
        <container >
            <div className="row card-container">
                < div className="col-lg-4 card-subject"><CardSubject image={dautres} title="Les figures de l'ombre" text='Découvrez ce film qui relate le destin de 3 femmes noires dans la NASA' file={`${process.env.PUBLIC_URL}/dep-figure-ombre.pdf`} /></div>              
            </div>
        </container>
        <ContactButton />
    </>
    );
}




export default Talk; 


