import '../App.scss';
import Programme from '../components/Programme';
import Raison from '../components/Raison';
import { Citation, CtaButton, ContactButton } from '../components/Tools';
import Header from '../components/Header'; 
import Aide from '../components/Aide';
import Contact from '../components/Contact';
import Pourquoi from '../components/Pourquoi';


function App() {
  return (
    <div className="App">
      <Header />
      
      <Citation sentence='« Nous ne pouvons tous réussir quand la moitié d’entre nous est retenu. »' author='Malala Yousafzai' />
      
      <Raison />

      <ContactButton />  

      <Pourquoi />
      
      <CtaButton />

      <Programme />
      <Aide />

      <CtaButton />
      <Contact />
    </div>
  );
}

export default App;

