import { Link } from 'react-router-dom';
import logo from '../logo.svg'; 

const Title = (props) => {
    return(
      <h3>{props.text}</h3>
    );
  }

const Citation  = (props) => {
    return(
      <div className='fs-5 sentence'>
          {props.sentence} <br />
          {props.author}
      </div>
    );
  }

const ContactButton = () =>{
  return(
    <center><a href="https://form.jotform.com/242456741033553" className="button ctabutton" target='_blank' rel="noreferrer">Formulaire de contact</a></center>
  );
}


const CtaButton = () =>{
  return(
    <center><a href="https://form.jotform.com/242457106543050" className="button ctabutton" target='_blank' rel="noreferrer">Laissez un avis</a></center>
  );
}

const DonButton = () =>{
  return(
    <center><a href="https://www.leetchi.com/fr/c/exxist-3385066?utm_source=copylink&utm_medium=social_sharing" className="button donbutton" target='_blank' rel="noreferrer">Faire un don</a></center>
  );
}


const ButtonPDF = (props) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div>
      <button
        role="link"
        onClick={() => openInNewTab(props.link)}
        className="donbutton"
      >
        Ouvrir
      </button>
    </div>
  );
}


const CardSubject = (props) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return(
    <>
        <div className="card card-subject">
        <center><img src={props.image} className="card-img-top" alt="..." /></center>
        <div className="card-body">
          <center><h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.text}</p>
          
          <button role="link" onClick={() => openInNewTab(props.file )} className="donbutton">
              Ouvrir
          </button>
          </center>
        </div>
      </div>
    </>
  );
}

const NavBrand = () =>{
  return(
    <nav className="navbar">
    <div className="container-fluid">
      <a className="navbar-brand" href="#raison">
        <Link to='/' >
        <img src={logo} alt="Bootstrap" width="150" height="150" />
        </Link>
      </a>
    </div>
    </nav>
  );
}




export { Title, Citation, CtaButton, DonButton , ButtonPDF, CardSubject, NavBrand, ContactButton}; 