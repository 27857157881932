
import { CardSubject, Title, NavBrand } from "../components/Tools";

import basket from '../supports/basket.svg';
import infirmier from '../supports/infirmier.svg';
import { CtaButton } from "../components/Tools";

import { HashLink } from 'react-router-hash-link';


const Savais = () =>{
    return(
        <>
            <NavBrand />
            <center><Title text="Savais-tu que ?..."/></center>
            <br />
            <br />
            <div className="back-home"><HashLink smooth to="/#programme" className="back-home-link">Revenir à l'accueil</HashLink> </div>
            <container >
                <div className="row card-container">
                    < div className="col-lg-4 card-subject"><CardSubject image={basket} title="Basket" text='Découvrez les personnalités féminines du basket dans le monde entier.' file={`${process.env.PUBLIC_URL}/stq-basket.pdf`} /></div>
                    < div className="col-lg-4 card-subject" ><CardSubject image={infirmier} title="Infirmier" text='Infirmier ou sage-femme ? Oui les hommes peuvent accéder à ce métier' file={`${process.env.PUBLIC_URL}/stq-infirmier.pdf`} /></div>              
                </div>
            </container>
            <CtaButton />
        </>
    );
}

export default Savais; 