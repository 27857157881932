import { Title, DonButton } from "./Tools";

const Aide = () => {
    return(
      <div className='row'>
      <div className='col-md-2'></div>
      <div className='col'>
        <div id="aide" className="bloc-text">
          <p>
              <Title text="On a besoin de vous"/>
              "Pour avancer plus vite, il faut avancer à plusieurs. Alors, en quoi pouvez-vous aider ? Listés ci-dessous quelques exemples 
              mais bien évidemment, ils ne sont pas exhaustifs. Libre à vous de proposer ce que vous avez en tête. On prend toutes les 
              bonnes ondes <span>&#128521;</span> <br /> 
              Voici quelques exemples:
              <ul>
                <li>Téléchargement du support et présentation du support dans une classe ou école de son choix partout dans le monde</li>
                <li>Financement d'impressions d'affiches pour des écoles qui en ont besoin. Le montant est laissé visible pour tout le monde dans un soucis de transparence</li>
                <li>Un petit email de remerciement ou d'encouragement</li>
                <li>Aide à la relecture et à la traduction (la traduction en anglais est prévue)</li>
                <li>Des propositions de sujets</li>
                <li>Une petite rencontre ou un petit call pour proposer votre expertise sur le design, marketing, communication...</li>
                <li>Et autant d'idées qui vous viennent à l'esprit</li>
              </ul>
              <DonButton />
          </p>
        </div>
        </div>
      <div className='col-md-2'></div>  
      </div>
    )
  }

  export default Aide;
  