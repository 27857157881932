import { Title } from "./Tools";

const Pourquoi = () => {
    return(
      <div className='row'>
      <div className='col-md-2'></div>
      <div className='col'>
        <div id="pourquoi" className="bloc-text">
          <p>
              <Title text="Pourquoi cette initiative?"/>
              L'initiative Exxist a été créée pour sensibiliser aux stéréotypes encore trop présents dans nos vies professionnelles et 
              personnelles. Il n’est pas rare d'entendre des remarques surprenantes lorsqu’une femme travaille dans des secteurs 
              traditionnellement masculins, comme l’informatique ou le bâtiment. De même, un homme exerçant des métiers perçus comme 
              féminins, tels que secrétaire ou sage-femme, suscite souvent des réactions similaires. Et cela sans parler des jugements 
              basés sur nos origines, nos croyances, ou nos différences <br /><br />

              Je crois profondément que chacun d'entre nous a une place unique dans ce monde, sans avoir à justifier son existence.
              Cela m'attriste d'entendre mes nièces dire qu'elles ne peuvent pas jouer au basket parce que "c'est un sport de garçons", 
              alors qu'elles adorent ça. Ou encore, de voir mon neveu frustré de ne pas être pris au sérieux pour un poste de secrétaire 
              ou de babysitter. Le plus préoccupant, c'est qu'avant même de franchir la porte d'une opportunité professionnelle, nous sommes
              déjà jugés sur notre genre, nos origines, et bien d'autres critères.
          </p>
        </div>
        </div>
      <div className='col-md-2'></div>  
      </div>
    )
  }

  export default Pourquoi;
  