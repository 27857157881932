
import { Title , NavBrand} from "../components/Tools";
import { HashLink } from "react-router-hash-link";
import { CtaButton } from "../components/Tools";

const Pepite = () =>{
    return(
        <>
            <NavBrand />
            <center><Title text="La pépite de la quinzaine - Sujet à venir"/></center>
            <br />
            <br />
            <div className="back-home"><HashLink smooth to="/#programme" className="back-home-link">Revenir à l'accueil</HashLink></div>
            <container >
                <div className="row card-container">
                <div className="row card-container">
                    <center>Sujets à venir... </center>
                    
                </div>    
                </div>
            </container>

            <CtaButton />

        </>
        );
}

export default Pepite; 