import { Title } from "./Tools";
import {Link } from 'react-router-dom'; 
import talks from '../icons/talks.svg';
import savais from '../icons/savais.svg';
import pepite from '../icons/pepite.svg';
import news from '../icons/news.svg'; 

const Programme = () =>{
    return (
      <>
      <div className='row programme' id="programme">
      <Title text="Choisissez et cliquez sur les titres des programmes"/>
        <div className='col-md-1' ></div>
        <div className='col-md-5 col-12'>
          <div className="card">
          <center><img src={savais} className="card-img-top" alt="Question mark icon " /></center>
            <div className="card-body">
            <h5 className="card-title"><Link to="know" className="linkspeco">Savais-tu que ?</Link> </h5>
              <p className="card-text">Savais-tu que il existe des femmes conductrices de travaux? 
                Savais-tu que il existe des hommes sage-femme? 
                Autant de fiches qui montrent des figures qui ont marqué leurs milieux
              </p>
            </div>
          </div>
        </div>
        <div className='col-md-5'>
          <div className="card" >  
              <center><img src={news} className="card-img-top" alt="News icon " /></center>
              <div className="card-body">
                <h5 className="card-title"><Link to="/oneday" className="linkspeco">Un jour, une nouveauté</Link></h5>
                <p className="card-text">Un jour, une invention. Ou un jour, une découverte. Autant de faits "atypiques" sur les 
                inventions ou les entreprises qu'on utilise à ce jour. L'histoire des inventeurs ou leader derrière notre quotidien. 
                </p>
              </div>
            </div>
        </div>
        <div className='col-md-1'></div>
      </div>
      <div className='row programme'>
        <div className='col-md-1'></div>
        <div className='col-md-5 col-12'>
          <div className="card" >
          <center><img src={talks} className="card-img-top" alt="Talk icon " /></center>
            <div className="card-body">
            <h5 className="card-title"><Link to="/talk" className="linkspec">D'autres en parlent</Link></h5>
              <p className="card-text">Etonnant mais certains doutent que les situations d'inégalités existent. Ici on parle des oeuvres 
              , associations, ... qui défendent, illustrent ou dénoncent ces faits.</p>
            </div>
          </div>
        </div>
  
        <div className='col-md-5'>
          <div className="card" >  
            <center><img src={pepite} className="card-img-top" alt="Love icon " /></center>
              <div className="card-body">
                <h5 className="card-title"><Link to="/pepite" className="linkspec">La pépite de la quinzaine</Link></h5>
                <p className="card-text">Cette partie présente des interviews de toi, de moi des personnes autour de nous 
                  dont on pourrait avoir des préjugés ou dont on voudrait s'inspirer. D'ailleurs un merci infini pour nos futurs volontaires</p>
              </div>
            </div>
        </div>
        <div className='col-md-1'></div>
      </div>
      </>
    );
  }

  
  export default Programme; 