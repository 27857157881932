import { Title } from "./Tools";

const Raison = () => {
    return(
      <div className='row'>
      <div className='col-md-2'></div>
      <div className='col'>
        <div id="raison" className="bloc-text">
          <p>
              <Title text="En quoi consiste cette initiative?"/>
              L'initiative EXXIST est une plateforme dans laquelle est mise à disposition des affiches permettant de 
              promouvoir les minorités dans un domaine majoritairement dominé par un groupe. L'idée est de provoquer le dialogue, de débattre,
              de faire connaitre et pourquoi pas d'inspirer certain(e)s sur leurs avenirs. Les plaquettes sont destinées aux pré-ado et aux adolescents 
              mais semblent plaire à tout le monde. Et d'ailleurs arriveront bientot des formats pour les plus petits. Alors prenez le temps de 
              voir les différents programmes. <br />
              <br />
              Initialement, le "X" en plus sur le logo était dédiée aux femmes comme le chromosome XX. Mais en regardant
              autour de moi, les femmes ne sont pas les seules victimes de ces préjugés. Alors, le concept a été repensé en X avec le facteur 
              X, ce facteur vraiment spécial chez une personne ou une chose, une qualité difficile à décrire avec des mots. <br /> <br />
  
              Pour ceux qui souhaitent apporter leur aide, vous êtes plus que les bienvenus. Si vous avez des conseils, une heure de votre temps,
              de l'énergie, un sourire, du savoir-faire, des dons (les dons serviront essentiellement à l'impression de posters pour des écoles en difficulté), 
              soyez les bienvenus. Vous pouvez me contacter via la rubrique <a href="#contacts" className='linkspec'>Contact</a>.  <br /> 
              Vous avez la rubrique <a href="#aide" className='linkspec'>On a besoin de vous</a> pour plus de détails <span className='emoticone'>&#128512;</span> <br /> 
              <br/>Je souhaite remercier l'ensemble de mon entourage (amis et famille) sans quoi ce projet n'aurait pas existé. 
          </p>
        </div>
        </div>
      <div className='col-md-2'></div>  
      </div>
    )
  }

  export default Raison;
  