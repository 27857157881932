
import { CardSubject, Title, NavBrand } from "../components/Tools";
import { HashLink } from "react-router-hash-link";

import gm from '../supports/gm.svg';
import wifi from '../supports/wifi.svg';
import { ContactButton } from "../components/Tools";

const UnJour = () =>{
    return(
        <>
            <NavBrand />
            <center><Title text="Un jour, une nouveauté"/></center>
            <br />
            <br />
            <div className="back-home"><HashLink smooth to="/#programme" className="back-home-link">Revenir à l'accueil</HashLink></div>
            <container >
                <div className="row card-container">
                    < div className="col-lg-4 card-subject"><CardSubject image={gm} title="General Motors" text='A ton avis ? Qui est à la tête de cette entreprise ?' file={`${process.env.PUBLIC_URL}/unjour-gm.pdf`} /></div>
                    < div className="col-lg-4 card-subject" ><CardSubject image={wifi} title="Wifi" text='Qui est la personne qui a inventé le wifi? ' file={`${process.env.PUBLIC_URL}/unjour-wifi.pdf`} /></div>   
                </div>
            </container>
            <ContactButton />
        </>
    );
}

export default UnJour; 