import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import Dropdown from 'react-bootstrap/Dropdown'; 

const Header = () =>{
    return(
      <nav className="navbar">
        <div className="container-fluid">
            <Link to='/' >
            <img src={logo} alt="Bootstrap" width="150" height="150" />
            </Link>
          
            <Dropdown className="d-outline mx-2"> 
              <Dropdown.Toggle id="dropdown-autoclose-true" variant="outline-warning" >
                Sommaire
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#raison">L'initiative ExXist</Dropdown.Item>
                <Dropdown.Item href="#programme">Les programmes</Dropdown.Item>
                <Dropdown.Item href="#aide">On a besoin de vous</Dropdown.Item>
                <Dropdown.Item href="#contacts">Contact</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        </div>
      </nav>
    );
  }

  export default Header; 