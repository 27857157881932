import React from 'react';
import './index.scss';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Talk from './pages/DautresParlent';
import Pepite from './pages/Pepite';
import Savais from './pages/SavaisTuQue';
import UnJour from './pages/UnJourUneNouveaute';
import Page404 from './pages/Page404';
import Programme from './components/Programme';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
          <Routes>
              <Route path="/" element={<App />} />
              <Route path="/programme" element={<Programme />} />
              <Route path="/talk" element={<Talk />} />
              <Route path="/pepite" element={<Pepite />} />
              <Route path="/know" element={<Savais />} />
              <Route path="/oneday" element={<UnJour />} />
              <Route path="*" element={<Page404 />} />
          </Routes>
                </Router>
  </React.StrictMode>,
document.getElementById('root')
); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




 
