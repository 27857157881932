import { NavBrand } from "../components/Tools";


const Page404 = () =>{
    return(
        <>
            <NavBrand />
            <div>
                <center><h1>Vous êtes perdu ! Retournez à l'accueil en cliquant sur le logo </h1></center>
            </div>
        </>
    ); 
}

export default Page404; 